<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">新會員註冊</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                新會員註冊
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row flex-column justify-content-center align-items-center">
          <div class="col-lg-6">
            <div class="text-center">
              <h1 class="mb-4 font-weight-bold text-capitalize">
                還沒有 i玩$ 帳號？
              </h1>
              <p class="text-dark font-size-18">免費註冊一個！<br><small class="text-danger"><b>* 為必填欄位</b></small></p>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-envelope" aria-hidden="true"></i
                ></span>
              </div>
              <input v-if="isThridPartyRegistering" :value="form.email" class="form-control" disabled style="height: 48px;"/>
              <input v-else-if="moneybarID" :value="responseMoneybar.email" class="form-control" disabled style="height: 48px;"/>
              <input
                v-else
                v-model="form.email"
                type="email"
                class="form-control"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                required
                style="height: 48px;"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-user-circle" aria-hidden="true"></i
                ></span>
              </div>
              <input v-if="isThridPartyRegistering" :value="form.name" class="form-control" disabled style="height: 48px;" />
              <input v-else-if="moneybarID" :value="responseMoneybar.name" class="form-control" disabled style="height: 48px;" />
              <input
              v-else
                v-model="form.name"
                type="text"
                class="form-control"
                placeholder="姓名"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style="height: 48px;"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-user-circle-o" aria-hidden="true"></i
                ></span>
              </div>
              <input
                v-model="form.nickname"
                type="text"
                class="form-control"
                placeholder="暱稱"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style="height: 48px;"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon4"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-birthday-cake" aria-hidden="true"></i
                ></span>
              </div>
              <input
                v-model="form.birth_year"
                type="text"
                class="form-control"
                placeholder="出生年（Ex: 1990）"
                aria-label="Year"
                aria-describedby="basic-addon1"
                style="height: 48px;"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon5"
                  ><i class="fa fa-phone" aria-hidden="true"></i
                ></span>
              </div>
              <input
                v-model="form.mobile"
                type="text"
                class="form-control"
                placeholder="手機號碼（Ex: 0912345678）"
                aria-label="Mobile"
                aria-describedby="basic-addon1"
                style="height: 48px;"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon6"
                  ><i class="fa fa-map-marker" aria-hidden="true"></i
                ></span>
              </div>
              <input type="text" v-model="form.address" class="form-control" placeholder="詳細地址（寄送發票用）" autocomplete="off" style="height: 48px;">
              <input type="text" autocomplete="off" style="width: 0; padding: 0; border: none;">
            </div>
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon7"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-unlock" aria-hidden="true"></i
                ></span>
              </div>
              <!-- for IE & Edge -->
              <input type="password" style="display: none;">
              <input
                v-if="isThridPartyRegistering"
                :value="'******'"
                disabled
                type="password"
                class="form-control"
                placeholder="密碼（六碼以上，包含英數）"
                aria-label="Password"
                aria-describedby="basic-addon1"
                autocomplete="off"
                style="height: 48px;"
              />
              <input
                v-else
                v-model="form.password"
                type="text"
                class="form-control password"
                placeholder="密碼（六碼以上，包含英數）"
                autocomplete="off"
                style="height: 48px;"
                onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon7"
                  ><span class="text-danger" style="font-size: 16px;">*</span><i class="fa fa-lock" aria-hidden="true"></i
                ></span>
              </div>
              <!-- for IE & Edge -->
              <input type="password" style="display: none;">
              <input
                v-if="isThridPartyRegistering"
                :value="'******'"
                disabled
                type="password"
                class="form-control"
                placeholder="再次確認密碼"
                aria-label="Password"
                aria-describedby="basic-addon1"
                style="height: 48px;"
              />
              <input
                v-else
                v-model="checkPassword"
                type="text"
                class="form-control password"
                placeholder="再次確認密碼"
                aria-label="Password"
                aria-describedby="basic-addon1" style="height: 48px;">
            </div>
            <div class="agree__checkbox mb-3">
              <input
                v-model="ruleCheck"
                type="checkbox"
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1">
                加入 moneybar 會員代表你已閱讀，且同意接受
                <router-link to="/privacy" target="_blank" class="text-danger">「moneybar會員服務條款」</router-link>
              </label>
            </div>
            <div
              class="form-group list-inline d-flex justify-content-around mb-5"
            >
              <button type="button" class="btn btn-danger text-uppercase" @click="submit">
                免費註冊
              </button>
            </div>
            <div class="or-seperator"><i>or</i></div>
            <div class="form-group">
              <FBLoginButton @FBLoginSuccess="loginWithFB" style="text-align: center;" />
              <GoogleLoginButton @GoogleLoginSuccess="loginWithGoogle" style="text-align: center;" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import FBLoginButton from '@/components/FBLoginButton.vue'
import GoogleLoginButton from '@/components/GoogleLoginButton.vue'
export default {
  name: 'SignUp',
  components: {
    FBLoginButton,
    GoogleLoginButton
  },
  data () {
    return {
      infoCheck: false,
      checkPassword: '',
      ruleCheck: false,
      form: this.initForm(),
      responseMoneybar: {},
      errMsg: ''
    }
  },
  computed: {
    isThridPartyRegistering () {
      return !!this.form.socialId
    },
    moneybarID () {
      return !!this.responseMoneybar.moneybarId
    }
  },
  created () {
    const vm = this
    if (window.regInfo) {
      if (window.regInfo.socialType === 'google') {
        vm.setFormWithGoogleData(window.regInfo)
      } else if (window.regInfo.socialType === 'fb') {
        vm.setFormWithFBData(window.regInfo)
      }
    }
  },
  mounted () {
    require('@/assets/js/base.js')

    const vm = this
    vm.regisByMoneybar()
  },
  methods: {
    submit () {
      if (this.isThridPartyRegistering) {
        this.regWithSocialAccount()
      } else {
        this.regWithEmail()
      }
    },
    regWithSocialAccount () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/regSocial`

      if (vm.form.email === '') {
        alert('Email 不可為空')
        return false
      }

      if (vm.form.name === '') {
        alert('姓名不可為空')
        return false
      }

      if (vm.form.nickname === '') {
        alert('暱稱不可為空')
        return false
      }

      if (vm.form.birth_year === '') {
        alert('出生年不可為空')
        return false
      }

      if (vm.ruleCheck === false) {
        alert('請先閱讀會員服務條款')
        return false
      }

      vm.form.birthday = `${vm.form.birth_year}-${vm.form.birth_month}-${vm.form.birth_day}`

      vm.axios.post(api, { ...vm.form, token: tokenGenerator() })
        .then((res) => {
          console.log(res)
          if (res.data.status === '000') {
            alert('註冊成功')
            vm.$router.push('/login')
          } else {
            if (Array.isArray(res.data)) {
              res.data.forEach((item) => {
                vm.errMsg += item.errmsg + ','
              })
              alert(`註冊失敗, ${vm.errMsg}`)
              vm.errMsg = ''
            } else if (res.data.errmsg) {
              alert(res.data.errmsg)
            } else {
              alert('註冊失敗')
            }
          }
        })
    },
    regWithEmail () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/regMember`

      if (vm.form.email === '') {
        alert('Email 不可為空')
        return false
      }

      if (vm.form.name === '') {
        alert('姓名不可為空')
        return false
      }

      if (vm.form.nickname === '') {
        alert('暱稱不可為空')
        return false
      }

      if (vm.form.birth_year === '') {
        alert('出生年不可為空')
        return false
      }

      if (!vm.isThridPartyRegistering && vm.form.password === '') {
        alert('密碼不可為空')
        return false
      } else {
        if (!vm.isThridPartyRegistering && vm.checkPassword === '') {
          alert('請確認密碼')
          return false
        }
      }

      if (vm.form.password !== vm.checkPassword) {
        alert('密碼不一致')
        return false
      }

      if (vm.ruleCheck === false) {
        alert('請先閱讀會員服務條款')
        return false
      }

      vm.form.birthday = `${vm.form.birth_year}-${vm.form.birth_month}-${vm.form.birth_day}`

      vm.axios.post(api, { ...vm.form, token: tokenGenerator() })
        .then((res) => {
          console.log(res)
          if (res.data.status === '000') {
            alert('註冊成功')
            vm.$router.push('/login')
          } else {
            if (Array.isArray(res.data)) {
              res.data.forEach((item) => {
                vm.errMsg += item.errmsg + ','
              })
              alert(`註冊失敗, ${vm.errMsg}`)
              vm.errMsg = ''
            } else if (res.data.errmsg) {
              alert(res.data.errmsg)
            } else {
              alert('註冊失敗')
            }
          }
        })
    },
    loginWithFB (payload) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/login`
      if (payload.email === undefined) {
        alert('您必須提供 Facebook 的 Email 以便註冊')
        return false
      }

      vm.axios.post(api, {
        ...payload,
        token: tokenGenerator(),
        socialType: 'fb',
        socialId: payload.id
      }).then((res) => {
        if (res.data.status === '005') {
          alert(res.data.errmsg)
        } else if (res.data.status !== '002') {
          // 取得 response 的 token
          vm.enterToken = res.data.token
          // 存入 localStorage
          localStorage.setItem('memberToken', vm.enterToken)
          // 打 member API 取得 memberId 和 FQ 等級
          vm.axios.post(`${process.env.VUE_APP_APIPATH}/iPlay/member/check`, {
            token: tokenGenerator(),
            memberToken: vm.enterToken
          })
            .then((res) => {
              // 取得 memberId
              vm.memberId = res.data.content.id
              // 存入 localStorage
              localStorage.setItem('memberId', vm.memberId)
              if (res.data.content.grade === 0) {
                vm.$router.push('/fqtest')
              } else {
                vm.$router.push('/member')
              }
            })
        } else {
          alert('此帳號尚未註冊，請填妥欄位後並點選"免費註冊"')
          this.setFormWithFBData(payload)
        }
      })
    },
    loginWithGoogle (payload) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/login`

      // 打 login API
      vm.axios.post(api, {
        ...payload,
        token: tokenGenerator(),
        socialType: 'google',
        socialId: payload.id
      })
        .then((res) => {
          if (res.data.status === '005') {
            alert(res.data.errmsg)
          } else if (res.data.status !== '002') {
            // 取得 response 的 token
            vm.enterToken = res.data.token
            // 存入 localStorage
            localStorage.setItem('memberToken', vm.enterToken)
            // 打 member API 取得 memberId 和 FQ 等級
            vm.axios.post(`${process.env.VUE_APP_APIPATH}/iPlay/member/check`, {
              token: tokenGenerator(),
              memberToken: vm.enterToken
            })
              .then((res) => {
                // 取得 memberId
                vm.memberId = res.data.content.id
                // 存入 localStorage
                localStorage.setItem('memberId', vm.memberId)
                if (res.data.content.grade === 0) {
                  vm.$router.push('/fqtest')
                } else {
                  vm.$router.push('/member')
                }
              })
          } else {
            alert('此帳號尚未註冊，請填妥欄位後並點選"免費註冊"')
            this.setFormWithGoogleData(payload)
          }
        })
    },
    setFormWithFBData (payload) {
      if (payload.email === undefined) {
        alert('您必須提供 Facebook 的 Email 以便註冊')
        location.href = '/login'
        return false
      }
      this.form = {
        ...this.form,
        name: payload.name,
        email: payload.email,
        socialId: payload.id,
        socialType: 'fb'
      }
    },
    setFormWithGoogleData (payload) {
      if (payload.email === undefined) {
        alert('您必須提供 Google 的 Email 以便註冊')
        location.href = '/login'
        return false
      }
      this.form = {
        ...this.form,
        name: payload.name,
        email: payload.email,
        socialId: payload.id,
        socialType: 'google'
      }
    },
    regisByMoneybar () {
      const vm = this

      if (sessionStorage.getItem('responseMoneybar') !== null) {
        vm.responseMoneybar = JSON.parse(sessionStorage.getItem('responseMoneybar'))
        console.log(vm.responseMoneybar)
        vm.form.name = vm.responseMoneybar.name
        vm.form.mobile = vm.responseMoneybar.mobile
        vm.form.address = vm.responseMoneybar.address
        vm.form.nickname = vm.responseMoneybar.nickname
        vm.form.moneybarID = vm.responseMoneybar.moneybarId
        vm.form.email = vm.responseMoneybar.email
      }
    },
    initForm () {
      return {
        email: '',
        name: '',
        nickname: '',
        mobile: '',
        password: '',
        sex: '',
        address: '',
        birthday: '',
        birth_year: '',
        birth_month: '01',
        birth_day: '01',
        moneybarID: null,
        socialType: '',
        socialId: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password {
  -webkit-text-security: disc;
}

.agree__checkbox label {
  position: relative;
  line-height: 1.4;
  margin-left: 10px;
  font-size: 1.25rem;
}

.agree__checkbox input[type='checkbox'] {
  width: 1em;
  font-size: inherit;
  margin: 0;
  transform: translateX(-9999px);
}

.agree__checkbox input[type='checkbox'] + label:before {
  position: absolute;
  content: '';
  left: -1.3em;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin: 0;
  border: none;
  border-radius: 10%;
  background-color: #bbbbbb;
}

.agree__checkbox input[type='checkbox'] + label:after {
  position: absolute;
  content: '';
  left: -1.3em;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin: 0;
  border: none;
  background-color: white;
  border-radius: 10%;
  transform: scale(0.8);
}

/*checked*/
.agree__checkbox input[type='checkbox']:checked + label:before {
  position: absolute;
  content: '';
  left: -1.3em;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin: 0;
  border: none;
  background-color: #3b88fd;
}

.agree__checkbox input[type='checkbox']:checked + label:after {
  position: absolute;
  content: '\2713';
  left: -1.3em;
  top: 0.2em;
  width: 1em;
  height: 1em;
  margin: 0;
  border: none;
  background-color: #3b88fd;
  border-radius: 10%;
  color: white;
  text-align: center;
  line-height: 1;
}

/*focused*/
.agree__checkbox input[type='checkbox']:focus + label:before {
  border: 0.1em solid #8eb9fb;
  box-shadow: 0 0 0.2em #3b88fd;
}
</style>
